<template>
  <div class="">
    <div class="font-normal leading-5">
      <span class="font-light">{{ entradasVendidas }}</span
      >/<span class="font-semibold">{{ totalEntradas }}</span>
    </div>
    <RangeBar
      v-if="showRange"
      :cuposDisponibles="entradasVendidas"
      :cuposTotales="cupoTotal"
      class="range-bar mt-1"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import RangeBar from "@/components/RangeBar.vue";

export default {
  props: {
    event: {
      type: Object,
      default: () => {
        return {};
      },
    },
    showRange: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      ventas: [],
    };
  },
  components: {
    RangeBar,
  },
  async mounted() {
    this.ventas = await this.obtenerVentas(this.event._id);

    console.log("Ventas para el evento", this.event._id, this.ventas);
  },
  computed: {
    entradasVendidas() {
      return this.ventas.total;
    },
    totalEntradas() {
      let total = 0;
      for (let entrada of this.event.entradas) {
        total += entrada.cupo * 1;
      }
      return total;
    },

    cantidadEntradas() {
      if (!this.ventas) return 0;
      return this.ventas.reduce((acc, venta) => {
        acc += venta.total;
        return acc;
      }, 0);
    },
    totalDispobibles() {
      return this.totalEntradas - this.entradasVendidas;
    },
    cupoTotal() {
      let total = 0;
      for (let entrada of this.event.entradas) {
        total += entrada.cupo * 1;
      }
      return total;
    },
  },
  methods: {
    ...mapActions("eventos-ventas-entradas", { obtenerVentas: "get" }),
    ...mapActions("pago-evento", { obtenerCompras: "find" }),
  },
};
</script>

<style>
.range-bar {
  min-width: 200px;
}
</style>