<template>
  <div>
    <div class="text-green-700 text-xs font-semibold" v-if="precios.alto === 0">
      GRATIS
    </div>
    <div v-else-if="precios.alto === precios.bajo">
      {{ formattedPrice(precios.alto) }}
    </div>
    <div v-else>
      {{ formattedPrice(precios.bajo) }} - {{ formattedPrice(precios.alto) }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    event: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    currency() {
      return this.event.preferredCurrency || "ars";
    },
    precios() {
      // this.event.entradas.precio[currency]
      // Recorrer las entradas y devolver {bajo: 100, alto: 200}
      let precios = {
        bajo: 0,
        alto: 0,
      };

      for (let entrada of this.event.entradas) {
        if (precios.bajo == 0) {
          precios.bajo = entrada.precio[this.currency];
        }

        if (precios.alto == 0) {
          precios.alto = entrada.precio[this.currency];
        }

        if (entrada.precio[this.currency] > precios.alto) {
          precios.alto = entrada.precio[this.currency];
        }
        if (entrada.precio[this.currency] < precios.bajo) {
          precios.bajo = entrada.precio[this.currency];
        }
      }
      return precios;
    },

    parsedPrice() {
      return Math.round(this.value * 100) / 100;
    },
  },
  methods: {
    formattedPrice(value) {
      let locale = "es-AR";
      if (this.currency == "eur") {
        // Europa
        locale = "es-ES";
      }

      let tmp = new Intl.NumberFormat(locale, {
        style: "currency",
        currency: this.currency,
        maximumFractionDigits: 0,
      }).format(value);

      if (this.currency == "ars") {
        return tmp.replace("$", "ARS");
      }
      if (this.currency == "usd") {
        return tmp.replace("$", "USD");
      }
      return tmp;
    },
  },
};
</script>

<style lang="css"></style>
