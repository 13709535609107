<template>
  <div class="w-full bg-gray-200 rounded-full h-1 dark:bg-gray-700">
    <div
      class="h-1 rounded bg-green-600"
      :style="{ width: progressBarWidth }"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    cuposDisponibles: {
      type: Number,
      required: true,
    },
    cuposTotales: {
      type: Number,
      required: true,
    },
  },
  computed: {
    progressBarWidth() {
      const percentage = (this.cuposDisponibles / this.cuposTotales) * 100;
      return `${percentage}%`;
    },
  },
};
</script>

<style scoped>
/* Estilos locales si es necesario */
</style>
