<template>
  <div class="">
    <w-top-bar>Eventos</w-top-bar>
    <div class="h-full p-5 bg-gray-200 overflow-x-scroll">
      <!-- <pre>
        {{ events }}
      </pre> -->
      <div class="flex items-center text-xs">
        <div
          class="flex items-center justify-between h-12 px-1 print:hidden my-2"
          style="width: 100%"
        >
          <div class="flex items-center text-xs">
            <label class="mr-2">Filtrar por</label>
            <select v-model="filter" class="w-32 rounded px-2 py-1 border-gray-400 text-sm">
              <option v-for="(type, index) in types" :key="index" :value="type.key">
                {{ type.label }}
              </option>
            </select>
          </div>
          <router-link
            to="/eventos/new"
            class="flex items-center text-sm text-blue-500 cursor-pointer hover:text-blue-600"
          >
            Nuevo evento
          </router-link>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="-my-2 sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div class="border-b border-gray-200 shadow sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50"
                    >
                      Evento
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50"
                    >
                      Participantes
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50"
                    >
                      Precio
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50"
                    >
                      Acciones
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-300">
                  <tr v-for="event in filteredEvents" :key="event._id">
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="w-20 h-20 rounded border overflow-hidden">
                          <img
                            :src="event.image"
                            alt="Imagen en miniatura redonda"
                            class="w-full h-full object-cover"
                          />
                        </div>
                        <div class="ml-4">
                          <div
                            v-tooltip="event.titulo"
                            style="max-width: 300px; overflow: hidden; text-overflow: ellipsis"
                            class="text-sm font-medium leading-5 text-gray-900"
                          >
                            {{ event.titulo }}
                          </div>
                          <div class="text-sm leading-5 text-gray-600 capitalize">
                            {{ event.date | formatDate }}
                          </div>
                          <div
                            v-if="event.tipo == 'presencial'"
                            class="text-sm leading-5 text-gray-600"
                          >
                            Evento Presencial
                          </div>
                          <div
                            v-if="event.tipo == 'streamings'"
                            class="text-sm leading-5 text-gray-600"
                          >
                            Evento en linea
                          </div>
                          <div class="flex items-center mt-2" v-if="event.estado == 'en-venta'">
                            <div class="w-4 h-4 rounded-full bg-green-500 mr-2"></div>
                            <p class="text-sm">En venta</p>
                          </div>
                          <div class="flex items-center mt-2" v-if="event.estado == 'agotado'">
                            <div class="w-4 h-4 rounded-full bg-yellow-500 mr-2"></div>
                            <p class="text-sm">Agotado</p>
                          </div>
                          <div class="flex items-center mt-2" v-if="event.estado == 'proximament'">
                            <div class="w-4 h-4 rounded-full bg-gray-500 mr-2"></div>
                            <p class="text-sm">Proximamente</p>
                          </div>
                          <div class="flex items-center mt-2" v-if="event.estado == 'cerrado'">
                            <div class="w-4 h-4 rounded-full bg-red-500 mr-2"></div>
                            <p class="text-sm">Cerrado</p>
                          </div>
                          <div class="flex items-center mt-2" v-if="event.estado == 'cancelado'">
                            <div class="w-4 h-4 rounded-full bg-red-500 mr-2"></div>
                            <p class="text-sm">Cancelado</p>
                          </div>
                        </div>
                      </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex flex-col space-y-3">
                        <evento-cupos
                          :event="event"
                          class="text-sm text-gray-800"
                          :key="event._id"
                        />
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <!-- <div class="text-sm leading-5 text-gray-900" v-html="event.extra.descripcion"></div> -->
                      <!-- ejemplo -->
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        <p>
                          <w-precio-entradas :event="event" />
                        </p>
                        <!-- <p>USD ${{ event.precio.usd }}</p> -->
                      </div>
                      <!-- saldo de linea  -->
                    </td>
                    <td class="px-6 py-12 whitespace-nowrap" style="display: block ruby">
                      <div class="flex items-center justify-end space-x-2">
                        <router-link
                          v-tooltip="'Validar entradas'"
                          class="flex items-center cursor-pointer p-1 py-1"
                          :to="{
                            name: 'eventos-id-verificar',
                            params: {
                              id: event._id,
                            },
                          }"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-4 h-4"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z"
                            />
                          </svg>
                        </router-link>
                        <router-link
                          v-tooltip="'Editar'"
                          class="flex items-center cursor-pointer p-1 py-1"
                          :to="{
                            name: 'eventos-id',
                            params: {
                              id: event._id,
                            },
                          }"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            class="w-4 h-4"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                            />
                          </svg>
                        </router-link>
                        <router-link
                          v-tooltip="'Ventas'"
                          class="flex items-center p-1 py-1 cursor-pointer"
                          :to="{
                            name: 'eventos-id-ventas',
                            params: {
                              id: event._id,
                            },
                          }"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-4 h-4"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"
                            />
                          </svg>
                        </router-link>
                        <!-- Botón para clonar el producto -->
                        <div class="text-xs text-left hover:bg-gray-100">
                          <a
                            @click="clonar(event._id)"
                            v-tooltip="'Clonar'"
                            class="flex items-center cursor-pointer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              class="w-4 h-4"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                              />
                            </svg>
                          </a>
                        </div>
                        <!-- Enlace para ir a la página del producto -->
                        <a
                          v-tooltip="'Ver página de ' + event.titulo"
                          target="_blank"
                          class="flex items-center text-xs text-left cursor-pointer hover:bg-gray-100"
                          :href="`https://tienda.orsai.org/eventos/${event.slug}`"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            class="w-4 h-4"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                            />
                          </svg>
                          <!-- </a> -->
                        </a>

                        <!-- Botón para eliminar el evento -->
                        <div
                          v-tooltip="'Eliminar'"
                          @click="deleteEvent(event._id)"
                          class="flex text-gray-400 items-center text-xs text-left cursor-pointer hover:text-red-600 rounded"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            class="w-4 h-4"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import EventoCupos from "@/components/nuevo/EventoCupos.vue";
export default {
  layout: "default",
  name: "eventos",
  components: {
    EventoCupos,
  },
  data() {
    return {
      selectedOrderType: "",
      events: [],
      filter: "en-venta",
      types: [
        { key: "", label: "Todos" },
        { key: "en-venta", label: "En Venta" },
        { key: "agotado", label: "Agotado" },
        { key: "proximament", label: "Proximamente" },
        { key: "cerrado", label: "Cerrado" },
        { key: "cancelado", label: "Cancelado" },
      ],
    };
  },
  async created() {
    await this.loadEvents();
  },
  methods: {
    ...mapActions("eventos", ["find", "remove", "get", "create"]),
    // ...mapActions("products", ["find"]),
    async clonar(event) {
      console.log("Clonando evento:", event);
      const evento = await this.get(event);
      console.log("Evento clonado:", evento);
      // Reemplazar el id del evento
      delete evento._id;
      // Reemplazar los valores del cada prop del objeto por cero
      Object.keys(evento.ventas || {}).forEach((key) => {
        evento.ventas[key] = 0;
      });
      // Reemplazar ventasEntradas por []
      evento.ventasEntradas = [];

      // Agregar " - Clonado"
      evento.titulo = "Clonado - " + evento.titulo;
      // Guardar el evento
      const nuevoEvento = await this.create(evento);
      // Notificar
      this.$toast.success("Evento clonado");
      // Redireccionar a editar el evento
      this.$router.push({
        name: "eventos-id",
        params: {
          id: nuevoEvento._id,
        },
      });
    },

    calcularCupos(ventas, cupos) {
      if (!ventas) return cupos;
      // ventas = {nombre:cantidad, nombre2:cantidad}

      const entradasVendidas = Object.values(ventas).reduce((a, b) => a + b, 0);
      return cupos - entradasVendidas;
    },
    editEvent(event) {
      console.log("Editing event:", event);
      // Implement the edit functionality
    },
    async deleteEvent(eventId) {
      console.log("Deleting event:", eventId);
      // Implement the delete functionality
      await this.remove(eventId);
      // Notificar
      this.$toast.error("Evento eliminado");
      // Reload
      await this.loadEvents();
    },
    async loadEvents() {
      try {
        this.events = (
          await this.find({
            query: {
              $sort: { date: 1 },
            },
          })
        ).data;
      } catch (error) {
        console.error("Error fetching event:", error);
      }
    },
  },
  filters: {
    formatDate(value) {
      return moment(value).format("MMMM Do YYYY, h:mm:ss A");
    },
  },

  computed: {
    filteredEvents() {
      if (!this.events) return [];
      if (!this.filter || this.filter === "") {
        return this.events;
      } else {
        return this.events.filter((event) => event.estado === this.filter);
      }
    },
    // Función computada que recibe el texto y lo formatea
    formatText() {
      return (text) => {
        const maxLength = 3; // Máxima longitud deseada

        if (text.length <= maxLength) {
          return text;
        } else {
          return text.slice(0, maxLength) + " ...";
        }
      };
    },
    // formattedDate() {
    //     return moment(this.originalDateTime).format('YYYY-MM-DD');
    // },
    // formattedTime() {
    //     return moment(this.originalDateTime).format('HH:mm:ss');
    // }
  },
};
</script>
